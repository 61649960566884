import React from 'react';
import FloatingLabel from 'floating-label-react';
import NumberFormat from 'react-number-format';
import _ from 'lodash';
import { Field } from 'formik';

export default ({
  name,
  placeholder,
  showErrorMessages = true,
  format,
  floating = true,
  prefix,
  suffix,
  maxlength,
  thousandSeparator,
  handler
}) => (
  <Field name={name}>
    {({ field, meta }) => (
      <div className="app-input-text">
        <NumberFormat
          prefix={prefix}
          suffix={suffix}
          placeholder={placeholder}
          format={format}
          customInput={floating ? FloatingLabel : null}
          name={field.name}
          className={`${meta.touched && meta.error ? 'error' : ''}`}
          maxLength={maxlength}
          thousandSeparator={thousandSeparator}
          onValueChange={values => {
            const { formattedValue } = values;
            _.isFunction(handler) && handler(formattedValue, name)
          }}
          {...field}
        />
        {meta.touched && meta.error && showErrorMessages && (
          <p className="error-message">{meta.error}</p>
        )}
      </div>
    )}
  </Field>
);
